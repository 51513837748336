<template>
    <div class="w-full flex flex-col shadow overflow-y-auto max-h-full scroll-bar">
        <div class="flex justify-between items-center gap-6 bg-primary-300 px-4 py-2 rounded-t-lg" v-if="!hideTitle">
            <slot name="title">
                <h2 class="text-base-content font-semibold text-lg">{{ title }}</h2>
            </slot>
            <div v-if="showActionBtn">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="bg-card-bg rounded-b-lg flex flex-col overflow-y-auto scroll-bar" :class="contentClass">
            <slot class="flex-1"> </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "card",
    data() {
        return {};
    },
    props: {
        title: {
            type: String,
            default: "Title",
        },
        showActionBtn: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        contentClass: [String, Array, Object],
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped></style>
