<template>
<div class="max-auto items-center  w-full">
    <FileBrowser class="mx-auto mt-10" title="UPLOAD_FILES" />
</div>
</template>

<script>
import FileBrowser from "@shared/filebrowser"

export default {
    components: {
        FileBrowser
    }
}
</script>

<style>

</style>