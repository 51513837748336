<template>
  <section class=" w-full text-base-content" v-allow:auth="'customDomain.read'">
        <Card title="Map Custom Domain" hideTitle contentClass="p-5">
            <div class="p-5">
                <h3 class="text-xl text-current font-medium mb-5">Map Domain Configuration</h3>

                <fieldset class="flex  space-x-6 mb-6  items-start">
                    <label for="domain-name" class="text-current w-2/12 mt-2  font-medium">Domain Name<span class="text-red-500">*</span></label>
                    <div class="form-control">
                        <!-- <label class="input-group  overflow-hidden mb-2  border  rounded-xl"> -->
                            <input type="text" placeholder="Please enter a domain" v-model="customDomainInput" 
                            class="input input-bordered  rounded-xl" :disabled="!isDomainEditable" :class="{'input-error': customDomainInputError, 'input-disabled bordered': !isDomainEditable }" />
                            <!-- <span class="text-primary bg-gray-100 font-medium">.delvium.com</span> -->
                        <!-- </label> -->
                        <small class="text-base-content-200 inline-block p-2 text-red-500">
                            This is an one time process, once saved can not be changed.
                        </small>
                    </div>
                    <!-- <div class="icon cursor-pointer">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_674_4406)">
                            <path d="M8.99962 0C4.02971 0 0 4.02971 0 8.99962C0 13.9695 4.02971 18 8.99962 18C13.9695 18 18 13.9695 18 8.99962C18 4.02971 13.9695 0 8.99962 0ZM10.8731 13.9482C10.4099 14.131 10.0411 14.2697 9.76457 14.3657C9.48876 14.4617 9.168 14.5097 8.80305 14.5097C8.24229 14.5097 7.80571 14.3726 7.49486 14.099C7.184 13.8255 7.02933 13.4789 7.02933 13.0575C7.02933 12.8937 7.04076 12.7261 7.06362 12.5554C7.08724 12.3848 7.12457 12.1928 7.17562 11.9771L7.75543 9.92914C7.80648 9.73257 7.85067 9.54591 7.88571 9.37219C7.92076 9.19695 7.93752 9.03619 7.93752 8.8899C7.93752 8.62933 7.88343 8.44648 7.776 8.34362C7.66705 8.24076 7.4621 8.19048 7.15657 8.19048C7.00724 8.19048 6.85333 8.21257 6.69562 8.25905C6.53943 8.30705 6.40381 8.35048 6.29257 8.39314L6.44571 7.76229C6.82514 7.60762 7.18857 7.47505 7.53524 7.36533C7.88191 7.2541 8.20952 7.19924 8.5181 7.19924C9.07505 7.19924 9.50476 7.33486 9.80724 7.60305C10.1082 7.872 10.2598 8.22171 10.2598 8.65143C10.2598 8.74057 10.2491 8.89752 10.2286 9.12152C10.208 9.34629 10.1691 9.55124 10.1128 9.73943L9.536 11.7813C9.48876 11.9451 9.44686 12.1326 9.40876 12.3421C9.37143 12.5516 9.35314 12.7116 9.35314 12.819C9.35314 13.0903 9.41333 13.2754 9.53524 13.3737C9.65562 13.472 9.86667 13.5215 10.1653 13.5215C10.3063 13.5215 10.464 13.4964 10.6423 13.4476C10.819 13.3989 10.947 13.3554 11.0278 13.3181L10.8731 13.9482ZM10.771 5.66019C10.5021 5.9101 10.1783 6.03505 9.79962 6.03505C9.42171 6.03505 9.09562 5.9101 8.82438 5.66019C8.55467 5.41029 8.41829 5.10629 8.41829 4.75124C8.41829 4.39695 8.55543 4.09219 8.82438 3.84C9.09562 3.58705 9.42171 3.46133 9.79962 3.46133C10.1783 3.46133 10.5029 3.58705 10.771 3.84C11.04 4.09219 11.1749 4.39695 11.1749 4.75124C11.1749 5.10705 11.04 5.41029 10.771 5.66019Z" fill="#0D69D5"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_674_4406">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div> -->
                </fieldset>
                <!-- <Card title="DNS Status"> -->
                <!-- </Card> -->
                <div class="flex justify-end mb-6">
                    <Button text="Save" type="primary" @click="saveCustomDomain" :disabled="!isDomainEditable || !checkPermission('customDomain.edit')" />
                </div>
                <Card title="DNS Configuration and Validation " class="mb-5 " v-if="showDomainRecords">
                    <div class="px-5 py-2 my-4" v-if="!loaders.dns && domain_records && domain_records.length">
                        <span class="alert alert-info font-mono  tracking-wide  text-base-content">
                            In order to use custom domain settings you must add following records to your DNS settings. You need to have access to administer your domain,
                            otheriwse you need the help of your administrator to complete this task.
                        </span>
                    </div>
                    <div class="p-5" v-if="!loaders.dns">
                        <div class="overflow-x-auto scroll-bar mb-5 bordered border" v-if="domain_records && domain_records.length">
                            <table class="table table-zebra      w-full">
                                <!-- head -->
                               
                                <tbody>
                                <!-- row 1 -->
                                    <tr v-for="row,row_idx in domain_records" :key="row_idx">
                                        <th>{{row_idx === 0 ? ' ' :  row_idx}}</th>
                                        <component v-for="col,idx in row" :key="idx" :is="row_idx === 0 ? 'th' : 'td' " >
                                            {{col}}
                                        </component>
                                    </tr>
                               
                                </tbody>
                            </table>
                            </div>
                        <div class="flex justify-end">
                            <Button text="Verify" type="primary" @click="verifyCustomDomain" :disabled="!isDomainEditable || !checkPermission('customDomain.edit')" />
                        </div>
                    </div>
                    <div class="flex items-center justify-center " v-else>
                        <Loader />
                    </div>
                </Card>

                 <Card title="SSL Procurement"  class="mb-6" v-if="showSSLProcurements" >
                    <div class="p-5">
                        <div class="form-control">
                            <!-- <label class="cursor-pointer flex-row-reverse label"> -->
                                <div class="flex items-center pointer-events-none" >

                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="16" height="16" rx="6" fill="#7b2"/>
                                        <path d="M6.43344 9.43519L5.86863 10.0018L7.00175 11.1314L7.56656 10.5648L6.43344 9.43519ZM12.5508 5.56509C12.8627 5.25219 12.862 4.74566 12.5491 4.43373C12.2362 4.12179 11.7296 4.12257 11.4177 4.43548L12.5508 5.56509ZM7.56656 10.5648L12.5508 5.56509L11.4177 4.43548L6.43344 9.43519L7.56656 10.5648Z" fill="white"/>
                                        <path d="M6.43431 10.5657L7 11.1314L8.13137 10L7.56569 9.43431L6.43431 10.5657ZM4.93593 6.80456C4.62351 6.49214 4.11698 6.49214 3.80456 6.80456C3.49214 7.11698 3.49214 7.62351 3.80456 7.93593L4.93593 6.80456ZM7.56569 9.43431L4.93593 6.80456L3.80456 7.93593L6.43431 10.5657L7.56569 9.43431Z" fill="white"/>
                                    </svg>

                                    <span class="label-text ml-2 "> SSL Procurement Status: </span>
                                    <strong  class=" ml-2 ">
                                        Completed
                                    </strong>
                                </div>
                            <!-- </label>    -->
                        </div>
                    </div>
                 </Card>
            </div>

            
            
            <!-- DIVIDER  -->
            <!-- <div class="border-0 mb-6 border-t border-gray-200 mt-1 w-full h-1 "></div>  -->




            <div class="p-5">
                <h3 class="text-xl text-current font-medium mb-6">Custom Email Domain Configuration</h3>
                <fieldset class="flex  space-x-6 mb-6  items-start">
                    <label for="domain-name" class="text-current w-2/12 mt-2  font-medium">Domain Name<span class="text-red-500">*</span></label>
                    <div class="form-control">
                        <input type="text"  v-model="customDomainEmailInput" class="input w-full input-bordered   rounded-xl" :class="{'input-error' : customDomainEmailInputError}" />
                    </div>
                </fieldset>
                <!-- <div class="block rounded-lg overflow-hidden bg-white text shadow-lg ">
                    <div
                        class="border-b-2  bg-primary-300 font-medium border-content px-6 py-3">
                        SPF and DKIM Records
                    </div>
                    <div class="p-6 w-full">
                        <label class="input-group mb-4 w-1/2">
                            <span class="w-24">SPF</span>
                            <input type="text" placeholder="enter spf" v-model="email.spf" class="w-full input input-bordered" />
                        </label>
                        <label class="input-group w-1/2">
                            <span class="w-24">DKIM</span>
                            <input type="text" placeholder="enter dkim"  v-model="email.dkim" class="w-full input input-bordered" />
                        </label>
                    </div>
                </div> -->
                <div class="flex justify-end">
                    <Button text="Save" type="primary" @click="saveEmailConfig" :disabled="isEmailDomailDisable" />
                </div>
            </div>
            <Card title="Email Configuration" class="my-5 " v-if="showEmailRecords">
                    <!-- <div class="px-5 py-2 my-4" v-if="!loaders.email && email_records && email_records.length">
                        <span class="alert alert-info tracking-wide text-lg text-base-content">
                            In order to use custom domain settings you must add following records to your DNS settings. You need to have access to administer your domain,
                            
                            otheriwse you need the help of your administrator to complete this task.
                        </span>
                    </div> -->
                    <div class="p-5" v-if="!loaders.email">
                        <div class="overflow-x-auto scroll-bar mb-5 " v-if="email_records && email_records.length">
                            <table class="table table-zebra bordered    border w-full">
                                <!-- head -->
                               
                                <tbody>
                                <!-- row 1 -->
                                    <tr v-for="row,row_idx in email_records" :key="row_idx">
                                        <th>{{row_idx === 0 ? ' ' :  row_idx}}</th>
                                        <component v-for="col,idx in row" :key="idx" :is="row_idx === 0 ? 'th' : 'td' " >
                                            {{col}}
                                        </component>
                                    </tr>
                               
                                </tbody>
                            </table>
                            </div>
                        <div class="flex justify-end">
                            <Button text="Verify Email" type="primary" @click="verifyEmailDomain" :disabled="isEmailDomailDisable" />
                        </div>
                    </div>
                    <div class="flex items-center justify-center " v-else>
                        <Loader />
                    </div>
                </Card>
        </Card>


  </section>
</template>

<script>
import Button from "@shared/components/button"
import Card from "@shared/components/card"
import Loader from "@shared/components/loader"
import axios from '@/axios'
import {checkPermission} from "@shared/utils/functions"
export default {
    components: {
        Button,
        Card,
        Loader
    },
    data() {
        return {
            customDomainInput:  '',
            customDomainInputError:  false,
            customDomainEmailInput: '',
            customDomainEmailInputError: false,
            email: {
                spf: '',
                dkim: ''
            },
           showDomainRecords: false,
           showEmailRecords:  false,
           showSSLProcurements: false,
            loaders: {
                dns : false,
                email: false
            },
            domain_records: [],
            email_records: [],
            isDomainEditable: true,
            isEmailDomailDisable: false, 
        }
    },
    methods: {
        checkPermission,
        checkURLRegex(url) {
            const regx = /^(\*\.)?(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])$/
            return regx.test(url)
        },

        async saveCustomDomain(){
            this.loaders.dns = true 
            let full_domain_name = this.customDomainInput.trim()
            let valid = this.checkURLRegex(full_domain_name)
            if (full_domain_name == '' || !valid) {
                this.$toast.error(valid ? `Please enter a domain name` : `Please enter a valid domain`)
                this.customDomainInputError = true
                this.loaders.dns = false

                return 
            }

            try {
                this.customDomainInputError = false
                const { data } = await axios.post(`tenant/domain/request`, {
                    full_domain_name
                })
                if (data) {
                    this.domain_records = data.domain_records || []
                    this.showSSLProcurements = data.final_ssl_status || false
                    this.showDomainRecords =  this.domain_records.length > 0 || false
                }
                
            } catch (error) {
                this.$toast.error(error.response.data.detail)
                console.log('error :>> saveCustomDomain', error);
            }
            this.loaders.dns = false
        },

        //* verify custom domain  details  
        async verifyCustomDomain() {
            this.loaders.dns = true 

            try {
                this.customDomainInputError = false
                const { data } = await axios.post(`tenant/domain/verify`, {})
                // console.log('data :>> ', data);
                this.domain_records = data.domain_records || []
                this.showSSLProcurements = data.final_ssl_status || false
                
            } catch (error) {
                console.log('error :>> ', error);
                
            }
            this.loaders.dns = false

        },

            //* get Domain details at load 
        async getCustomDomainDetail() {
            this.showDomainRecords = true
            this.loaders.dns = true
             try {
                 const { data } = await axios(`tenant/domain/status`)
                console.log('data :>> ', data);
                
                this.domain_records = data.domain_records || []
                this.showSSLProcurements = data.final_ssl_status || false
                this.customDomainInput = data?.requested_domain || ''
                this.isDomainEditable = data.is_domain_editable

                
            } catch (error) {
                console.log('error :>> ', error);
                
            }
            this.showDomainRecords = this.domain_records.length > 0
            this.loaders.dns = false

        },

            //* get Email Domain details at load 
        async getEmailDomainDetail() {
            // this.showEmailRecords = true
            // this.loaders.dns = true
             try {
                 const { data } = await axios(`tenant/domain/email-domain`)
                if (data) {
                    this.email_records = data.email_domain_records || []
                    // this.showSSLProcurements = data.final_ssl_status || false
                    this.customDomainEmailInput = data?.email_address || ''
                    this.isEmailDomailDisable = data.email_domain_verified || data.active
                    this.showEmailRecords = data?.email_address !== null
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
            // this.loaders.dns = false

        },

        //* verify email  details  
        async verifyEmailDomain() {
            try {
                this.customDomainInputError = false
                const { data } = await axios.get(`tenant/domain/email-domain-verify`)
                // console.log('data :>> ', data);
                this.email_records = data.data || []
                // this.showSSLProcurements = data.final_ssl_status || false
                
            } catch (error) {
                if (Array.isArray(error.response.data.detail)) {
                    this.$toast.error(error.response.data.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error.response.data.detail || `Something went wrong!`)
                }
            }

        },

        // * save email domain config
        async saveEmailConfig() {
            // console.log('object :>> ', 'object');
            let full_domain_name = this.customDomainEmailInput.trim()
            if (full_domain_name == '') {
                this.$toast.error(`Please enter valid domain name`)
                this.customDomainEmailInputError = true
                return 
            }

            try {
                this.customDomainEmailInputError = false
                this.loaders.email = true 
                let body = new FormData()
                body.append('domain', full_domain_name)
                
                const { data }  = await axios.post(`tenant/domain/email-domain`,  body, {  headers: { "Content-Type": "multipart/form-data" }} )
                if (data.success) {
                    this.email_records = data.data || []
                    this.showEmailRecords =  this.email_records.length > 0
                }
            } catch (error) {
                if (Array.isArray(error.response.data.detail)) {
                    this.$toast.error(error.response.data.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error.response.data.detail || `Something went wrong!`)
                }
            }
            this.loaders.email = false
        },

    },

    async created() {
        await this.getCustomDomainDetail();
        await this.getEmailDomainDetail();
    }
}
</script>

<style>

</style>